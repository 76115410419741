import { NotificationService } from './../../../notification.service';
import { Component, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { SVGIcon, filePdfIcon, fileExcelIcon } from '@progress/kendo-svg-icons';
import {
  SelectableSettings,
  SelectableMode,
} from '@progress/kendo-angular-grid';
import { Router } from '@angular/router';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import {
  FilterableSettings,
  DataBindingDirective,
  GridDataResult,
  PageChangeEvent,
  DataStateChangeEvent,
  GridComponent,
} from '@progress/kendo-angular-grid';
import { Store, select } from '@ngrx/store';
import { process, State } from '@progress/kendo-data-query';
import { SharedService } from '../../../../shared/shared.service';
import { Subscription } from 'rxjs';
import {
  orderBy,
  SortDescriptor,
  filterBy,
  CompositeFilterDescriptor,
  FilterDescriptor,
} from '@progress/kendo-data-query';
import {
  getconsumptionHistoryExportResponse,
  getconsumptionHistoryResponse,
} from '../../../../store/customer/consumer-selector';
import {
  getConsumptionHistory,
  getConsumptionHistoryExport,
  resetConsumptionHistoryState,
} from '../../../../store/customer/consumer.action';
import { table_pageSize } from '../../../../shared/constants/templatesNames';
import { Roles, SweetAlertIcon } from '../../../../shared/constants/enum';
import { Input, Output, EventEmitter } from '@angular/core';
import { LoaderService } from '../../../../core/services/loader.service';
import { PdfexportService } from '../../../../core/services/pdfexport.service';

import { formatDate } from '@angular/common';  // <-- Import formatDate from common
import { CdkStepper } from '@angular/cdk/stepper';
@Component({
  selector: 'app-rp-consumption-history',
  templateUrl: './rp-consumption-history.component.html',
  styleUrl: './rp-consumption-history.component.scss',
})
export class RpConsumptionHistoryComponent {

  @ViewChild('cdkStepper') stepper: CdkStepper | undefined;
  @Input() meterViewDetails: any; // Replace `any` with the appropriate type
  @Output() meterInfo = new EventEmitter<any>();
  selectedDateRange: Date[] | undefined;
  public selectableSettings!: SelectableSettings;
  public checkboxOnly = true;
  public drag = false;
  public mode: SelectableMode = 'multiple';
  public filterMode: FilterableSettings = 'row';
  public consumptioPageSize = 10;
  projectId: any;
  consumerId: any;
  currentUser: any;
  public pageSizes = table_pageSize.pageSizes;
  //public pageSizes = true;
  public previousNext = true;
  public consumptionRechargesState: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  roles = Roles;
  public sort: SortDescriptor[] = [];
  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  orderByQuery: string = '';
  queryString: string = '';
  searchedValue: any = '';

  @ViewChild('pdfGrid') public hiddenGrid: GridComponent | undefined;
  public pageSize = 10;
  public skip = 0;

  totalUserCount: number = 0;
  currentPage: number = 1;
  public consmptiongridData: GridDataResult = { data: [], total: 0 };
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  //public gridData: unknown[] = [];

  breadCrumbItems: Array<{}> | undefined;

  public filePdfIcon: SVGIcon = filePdfIcon;
  public fileExcelIcon: SVGIcon = fileExcelIcon;
  public mySelection: string[] = [];
  readingQueryString: string = '';
  hidden!: boolean;

  datePickerConfig: Partial<BsDatepickerConfig>;
  private highconsumptionSubscription: Subscription;
  constructor(
    private modalService: BsModalService,
    private router: Router,
    private loaderService: LoaderService,
    private store: Store,
    private sharedService: SharedService,
    private PdfexportService: PdfexportService,
    private notificationService: NotificationService
  ) {
    this.datePickerConfig = Object.assign(
      {},
      {
        dateInputFormat: 'YYYY-MM-DD', // Customize as needed
        showWeekNumbers: false,
        isAnimated: true,
        containerClass: 'theme-dark-blue',
      }
    );
    this.highconsumptionSubscription = this.notificationService.highConsumptionnUpdateSubjectUpdate$.subscribe(data => {
      this.updateHighConsumptionData(data);
    });


    this.breadCrumbItems = [{ label: 'Consumption History' }];

    this.searchedValue = history.state.info ?? '';




    this.getConsumptionStoreInfo();
  }
  @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;
  consumptionHisttory: any[] = [];

  isAllOpen: boolean = true;

  consumtionGridView: boolean = true;
  consumtionDetailView: boolean = false;

  modalRef!: BsModalRef;
  config: any = {
    backdrop: true,
    ignoreBackdropClick: true,
  };

  ngOnChanges() {
    if (this.meterViewDetails) {
      // console.log();
      // Logic when meterViewDetails changes
    }
  }

  public onFilter(value: Event): void {
    this.consumptionPageChange({ skip: 0, take: this.currentPage })
    this.getConsumptionHistory();
  }

  public onValueChange(ev: string): void {
    if (ev.length == 0) {
      this.consumptionPageChange({ skip: 0, take: this.currentPage })
      this.getConsumptionHistory()
    }
  }

  onSearchConsumptionUnits(): void {
    if (this.searchedValue.length > 0) {
      this.getConsumptionHistory()
    }
  }

  updateHighConsumptionData(data: any) {
    this.searchedValue = data;
    this.getConsumptionHistory();
    // Trigger any additional logic needed to refresh the page
  }


  filterReadings(type: string) {
    if (type != 'filter') {
      this.selectedDateRange = undefined;
      this.queryString = '';
      this.getConsumptionHistory();
    } else {
      this.getConsumptionHistory();
    }
  }

  onDateChange(selectedDates: (Date | undefined)[] | undefined): void {
    if (selectedDates && selectedDates.length === 2) {
      const startDate = selectedDates[0];
      const endDate = selectedDates[1];

      if (startDate && endDate) {
        // Format dates to yyyy-MM-dd
        const formattedStartDate = startDate.toISOString().split('T')[0];
        const formattedEndDate = endDate.toISOString().split('T')[0];

        // Construct the filter query in the required format
        const filterQuery = `$filter=(finalReadingDate ge ${formattedStartDate} and finalReadingDate le ${formattedEndDate})`;

        console.log('Filter Query:', filterQuery);
        this.queryString = filterQuery;

        // You can now use this filter query to apply to your data or send it to an API
      } else {
        console.log('Invalid date range');
      }
    } else {
      console.log('No valid dates selected');
    }
  }

  ngAfterViewInit(): void {
    const savedStepIndex = localStorage.getItem('selectedTabIndex');
    if (savedStepIndex !== null && this.stepper) {
      this.stepper.selectedIndex = +savedStepIndex;  // Convert string to number
      localStorage.removeItem('selectedTabIndex');  // Optionally clear it after use
    }
    if (this.searchedValue) {
      this.getConsumptionHistory()
    }
  }

  getConsumptionHistory() {
    console.log(this.queryString)
    this.store.dispatch(
      getConsumptionHistory({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        ProjectId: this.projectId,
        MeterId: this.meterViewDetails?.id,
      })
    );
  }
  isFilter: any
  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '');
    this.isFilter = localStorage.getItem('filter');
    this.consumerId = this.currentUser?.currentUserRoleDetails?.userId;
    this.projectId = this.currentUser?.project.id;
    this.selectableSettings = {
      checkboxOnly: this.checkboxOnly,
      mode: this.mode,
      drag: this.drag,
    };
    const savedFilter = this.sharedService.getFilters('consumption');
    console.log(savedFilter)
    if (this.isFilter && savedFilter) {
      console.log(savedFilter)
      this.consumptionRechargesState.filter = savedFilter;
      console.log(this.filter)
      console.log(this.consumptionRechargesState)
      this.queryString = this.sharedService.createQuery(this.consumptionRechargesState);
      console.log(this.queryString)
      this.getConsumptionHistory();
    } else {
      this.getConsumptionHistory();
    }
    /* if (this.isFilter == 'true') {
      this.filter = this.sharedService.getFilter();
      this.consumptionRechargesState.filter = this.filter;
      console.log(this.filter)
      console.log(this.consumptionRechargesState)
      this.queryString = this.sharedService.createQuery(this.consumptionRechargesState);
    } */





  }
  private exportSubscription: Subscription = new Subscription();
  exportData: any[] = [];
  getConsumptionStoreInfo() {
    this.store.dispatch(resetConsumptionHistoryState());
    let getConsumptionHistory$ = this.store.pipe(
      select(getconsumptionHistoryResponse)
    );
    this.exportSubscription = this.store
      .pipe(select(getconsumptionHistoryExportResponse))
      .subscribe((res: any) => {
        // alert(5)
        if (res) {
          this.exportData = [];
          if (res?.items.length > 0) {
            this.exportData = [...res.items];
            const transformedData = this.exportData?.map((item: any) => ({
              Utility: item.utility === 'Electricity' ?
                `${item.utility} (${item.isEB ? 'EB' : 'DG'})` :
                item.utility,
              'Device Sr No': item.meterSrNo,
              'Meter No': item.macAddress,
              Consumption: item.utility === 'Water' ? `${item.consumptionUnits} L` :
                item.utility === 'Electricity' ? `${item.consumptionUnits} kWh` :
                  item.utility === 'Gas' ? `${item.consumptionUnits} m³` : item.consumptionUnits,
              'Unit Rate': item.unitRate, // Assuming no special formatting is needed for export
              'Amount Deducted': item.amountDeducted,
              'Available Balance': item.availableBalance,
              'Initial Reading': item.initialMeterReading,
              'Final Reading': item.finalMeterReading,
              'Initial Reading Date': this.dateFormateChnage(item.previousReadingDate),
              'Final Reading Date': this.dateFormateChnage(item.finalReadingDate),
              Date: this.dateFormateChnage(item.finalReadingDate),
              'Service Name': item.serviceName,
              'GST Percentage': item.gstPer,
              'GST Amount': item.gstAmount,
              'Bill Amount': item.totalAmount
            }));
            this.PdfexportService.downloadExcel(
              transformedData,
              'ConsumptionHistory'
            );
          } else {
            this.sharedService.showMessageDialog(
              'No data to display',
              '',
              SweetAlertIcon.ERROR
            );
          }
        }
      });
    getConsumptionHistory$.subscribe((res: any) => {
      if (res) {
        this.consumptionHisttory = [...res.items];
        console.log('res', res);
        this.pageSize = res.pageSize;
        this.totalUserCount = res.totalCount;
        this.pageSize = this.pageSize
        this.loadGridTable();
      }
    });
  }
  exportAll() {
    this.store.dispatch(
      getConsumptionHistoryExport({
        ProjectId: this.projectId,
        criteria: 'All',
      })
    );
  }

  dateFormateChnage(inputDate: any) {
    const date = new Date(inputDate);

    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true, // 12-hour format with AM/PM
    };
    const formattedDate = date.toLocaleString('en-GB', options).replace(',', '').replace(' ', ' ');
    return formattedDate
  }

  ngOnDestroy() {
    if (this.highconsumptionSubscription) {
      this.highconsumptionSubscription.unsubscribe();
    }
    //this.sharedService.clearFilter('consumption')
    this.exportSubscription.unsubscribe(); // Or set it to [] if you prefer an empty array
  }

  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective | undefined;
  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;

    // Check if the element is a table cell
    if (element.nodeName === 'TD') {
      const cellValue = element.innerText.trim();

      // Only show the tooltip if the cell is not empty, null, or ''
      if (cellValue && !element.closest('.no-tooltip')) {
        this.tooltipDir?.toggle(element);
      } else {
        this.tooltipDir?.hide();
      }
    } else if (element.classList.contains('k-column-title')) {
      this.tooltipDir?.toggle(element);
    } else {
      this.tooltipDir?.hide();
    }
  }
  public onExport(type: string) {
    /* if (this.selectedRowdata.length == 0) {
      this.store.dispatch(getConsumptionHistoryExport({ ProjectId: this.projectId, criteria: 'All', }));
    } */
    if (type === 'Excel') {
      console.log(this.consmptiongridData.data.length)
      if (this.consmptiongridData.data.length > 0) {
        setTimeout(() => {
          this.hiddenGrid?.saveAsExcel();
        }, 1000);
      } else {
        this.sharedService.showMessageDialog(
          'No data to display',
          '',
          SweetAlertIcon.ERROR
        );
      }
    } else if (type === 'Pdf') {
      setTimeout(() => {
        this.hiddenGrid?.saveAsPDF();
      }, 1000);
    }
  }
  loadGridTable() {
    const state: any = {
      take: this.pageSize,
      sort: this.sort,
      filter: this.filter,
    };
    const processedData = process(this.consumptionHisttory, state);
    this.consmptiongridData = {
      data: this.consumptionHisttory,
      total: this.totalUserCount,
    };
    this.loaderService.setLoading(false);
  }


  openCalendar() {
    // Trigger the click event on the input field to open the datepicker
    const dateInput = document.querySelector('.form-control.date');
    dateInput?.dispatchEvent(new Event('click'));
  }

  formatDateRange(dateRange: (Date | null)[]): string {
    if (dateRange && dateRange.length === 2 && dateRange[0] && dateRange[1]) {
      const startDate = formatDate(dateRange[0], 'dd/MM/YYYY', 'en-US');
      const endDate = formatDate(dateRange[1], 'dd/MM/YYYY', 'en-US');
      return `${startDate} to ${endDate}`;
    }
    return '';  // Return empty string if the date range is not valid
  }

  clearDate() {
    this.selectedDateRange = [];// Clear the selected date
  }




  viewconsumption(dataItem: any) {
    localStorage.setItem('selectedTabIndex', '1');
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '');
    if (this.currentUser?.currentUserRoleDetails?.role != this.roles.ADMIN) {
      this.router.navigate(['/consumptionHistory/consumptionView'], {
        state: { consumption: dataItem },
      });
    } else {
      this.sharedService.setConsumptionViewDetails(
        JSON.stringify(this.meterViewDetails)
      );
      this.router.navigate(['/meters/consumptionView'], {
        state: { consumption: dataItem },
      });
    }
  }
  backConsumption(event: any) {
    this.consumtionGridView = true;
    this.consumtionDetailView = false;
  }

  onSortChange(sort: SortDescriptor[]): void {
    this.consumptionRechargesState.sort = sort;
    console.log(this.consumptionRechargesState.sort);
    const orderByQuery = this.sharedService.createOrderByQuery(
      this.consumptionRechargesState.sort
    );

    this.orderByQuery = orderByQuery;

    this.getConsumptionHistory();
  }

  protected consumptionPageChange({ skip, take }: PageChangeEvent): void {
    console.log('>>>', skip, take);
    this.selectedRowdata = [];
    this.skip = skip;
    this.pageSize = take;
    this.currentPage = Math.floor(skip / take) + 1;
    console.log('currentpage', Math.floor(skip / take) + 1);
    this.getConsumptionHistory();
    this.loadGridTable();
  }

  protected consumptionStateChange(state: DataStateChangeEvent): void {
    this.sort = state.sort || [];
    this.filter = state.filter || { logic: 'and', filters: [] };
    this.loadGridTable();
  }

  public onConsumptionFilterChange(filter: CompositeFilterDescriptor): void {
    this.consumptionRechargesState.filter = filter;
    console.log(this.consumptionRechargesState);
    this.skip = 0
    let isFilter = filter.filters.length > 0 ? true : false;
    localStorage.setItem('filter', JSON.stringify(isFilter));
    this.sharedService.setFilters('consumption', filter);
    const queryString = this.sharedService.createQuery(
      this.consumptionRechargesState
    );
    this.queryString = this.sharedService.createQuery(
      this.consumptionRechargesState
    );
    this.currentPage = 1
    this.getConsumptionHistory();
    console.log(queryString);
  }

  selectedRowdata: any = [];
  deselectedRowdata: any = [];
  onSelect(event: any) {
    console.log(event.selectedRows);
    var selectedval = event.selectedRows;
    var deselectedVal = event.deselectedRows;
    if (selectedval.length > 0) {
      selectedval.forEach((element: any) => {
        this.selectedRowdata.push(element.dataItem);
        console.log(' selected Values : ', this.selectedRowdata);
      });
    }
    if (deselectedVal.length > 0) {
      deselectedVal.forEach((element: any) => {
        this.deselectedRowdata.push(element.dataItem);
        console.log('deselectedRowdata Values : ', this.deselectedRowdata);
      });

      if (this.deselectedRowdata.length > 0) {
        const idsToRemove = new Set(
          this.deselectedRowdata.map((item: { id: any }) => item.id)
        );

        const filteredArray = this.selectedRowdata.filter(
          (item: { id: unknown }) => !idsToRemove.has(item.id)
        );
        console.log('', filteredArray);
        this.selectedRowdata = filteredArray;
      }
    }
  }
}
